























import { Component, Prop, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { mixins } from "vue-class-component";
const contentsModule = namespace("contents");

import { ApiRequest } from "@/store/types";

import { ContentGetterTypes, ContentActionTypes } from "@/store/contents/types";
import { Content, Mentor } from "@/models";
import { contents } from "@/store/contents";

import Kennisgever from "@/assets/kennisgever.svg";
import Meerzien from "@/assets/meerzien.svg";
import ContentComponent from "./ContentComponent";

@Component({ components: { Kennisgever, Meerzien } })
export default class AskQuestionCard extends mixins(ContentComponent) {
  constructor() {
    super();
    this.componentName = "ask question card";
  }
}
